import React from "react";

import { GetServerSideProps } from "next";
import { getServerSidePropsDefalutSetting } from "../ssr/SSR";

const index = () => {
  return <div></div>;
};

export const getServerSideProps: GetServerSideProps =
  getServerSidePropsDefalutSetting;

export default index;
